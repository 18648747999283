:root {
  --viewport-width: 100vw;
  --viewport-height: 100vh;
}

body {
  margin: 0;
  font-family: 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  cursor: url(../public/images/cursor.svg), auto;
  background-color: white;
  color: #0E2673;
  overflow-x: hidden !important;
  letter-spacing: -0.8px;
}

.logo-container .logo,
.logo-container .circle-game-logo {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 6em;

}

@font-face {
  font-family: "GothicNew";
  src: url(../public/fonts/NewsGothicStd.otf);
}

@font-face {
  font-family: "GothicNewBold";
  src: url(../public/fonts/NewsGothicStd-Bold.otf);
}

@font-face {
  font-family: "avenir";
  src: url(../public/fonts/AVENIR.OTF);
}

/* MODEL */

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  background-color: white;
  padding: 8px 20px;
  color: black;
  z-index: 100;
  text-transform: uppercase;
  color: #2E3192;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Box shadow properties */
}

.transform-progress-bar {
  transform: rotate(180deg);
}

.countdown-number {
  color: #EC1631;
  font-size: 24px;
  font-weight: 600;
}

.countdown-text {
  margin: 0px;
}

.modal.show {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 250px;
}

.arrow-countdown-icon {
  width: 70px;
}

/* Additional styling for the overlay to cover the entire screen */
.overlay-model {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay-model.show {
  display: block;
}


.countdown-container {
  top: 25%;
  right: 15%;
  position: absolute;
  z-index: 5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rounds-score {
  /* position: absolute;
  top: 10px;
  right: 10px;
  width: 7em;
  height: 7em; */
  top: 20px;
  background-color: rgba(255, 0, 0, 0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  user-select: none;
  transform: scale(0.7);
}

.rounds-score-inner-border {
  display: flex;
  flex-direction: row;
  border-radius: 22px;
  border: 1px solid #2E3192;
}

.rounds {
  padding: 10px 18px;
  color: #2E3192;
  text-transform: uppercase;
}

.outer-border-red {
  border-radius: 30px;
  border: 5px solid #EC1631;
  padding: 5px;
}

.score {
  padding: 10px 18px;
  background-color: #D8D6D6;
  color: #2E3192;
  overflow: hidden;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  text-transform: uppercase;
}

.rounds-score p {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 2;
}

.pick-text p {
  margin: 18px;
}

.deck {

  display: flex;
  flex-wrap: wrap;
  margin: 10px 50px 20px 50px;
  justify-content: center;
}

.situation-card {
  border-radius: 5%;
  width: calc(18% - 10px);
  border-radius: 5%;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.3);
}

.actionCard {
  box-shadow: 0px 0px 5px 5px rgb(223 31 31 / 55%);
}

.category-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 90px;
}

.category-card {}

.game-buttons {}

.picked-card {
  display: inline-block;
  position: absolute;
  top: 10.5%;
  right: calc(-32vw + 10px);
  width: calc(80% - 1vw);
}

.category-card-container {
  display: inline-block;
  /* Styles for each card item */
  /* Adjust the width, height, margin, and other styles as per your requirements */
  width: calc(14% - 10px);
  height: calc(14% - 10px);
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5%;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.3);
  margin: 20px 25px;
  padding: 0px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  position: initial;
}

.hidden {
  width: 0;
  height: 0;
  box-shadow: 0 0px 0px rgb(0 0 0 / 0%);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  pointer-events: none;
}

.category-card.selected {
  display: inline-block;
  transform: scale(1.3);
  top: -15em;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 2;
  width: 200px;
  height: 280px;
  z-index: 5;
  border-radius: 5%;
}

.category-card.selected+.category-card-container {
  display: none !important;
}

.category-card-png {
  text-align: center;
}

.category-card-text {
  font-weight: bold;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.exit-button,
.pick-button {
  border: none;
  padding: 8px 24px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
}

.exit-button a,
.pick-button a {
  text-decoration: none;
  color: white;
}

.progress-bar-container {
  background-color: #F4F3F3;
  padding: 20px 18px;
  border-radius: 25px;
  width: 500px;
  margin-bottom: 40px;
}

.welcome-text {
  color: #252B68;
  font-size: 18px;
  font-weight: bolder;
  text-transform: uppercase;
  /* font-family: 'GothicNewBold'; */
}

.logo-welcome {
  width: 80px;
  /* font-family: 'GothicNewBold'; */
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.welcome-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.welcome-small {}

.result-item p {
  color: #E31732;
  font-weight: bold;
  font-size: 35px;
  margin-top: 10px;
  /* font-family: 'GothicNewBold'; */

}

.islamophobia {
  background-color: #E31732;
}

.childism {
  background-color: #252B68;
}

.discrimination {
  background-color: #E25A70;
}

.homophobia {
  background-color: #F23454;
}

.religious {
  background-color: #B8C2D8;
}

.racism {
  background-color: #6E7BD6;
}

.classism {
  background-color: #0820C1;
}

.sexism {
  background-color: #9097A4;
}

.religious_bigotry {
  background-color: #B8C2D8;
}


.circle-key-circle {
  width: 18px;
  height: 18px;
  border-radius: 100%;
}

.result-spectrum-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  align-items: center;
}

.english-class {
  font-family: "GothicNewBold";
}

.other-language {
  font-family: "avenir";
  /* direction: rtl; */
}

.other-language .result-spectrum-buy  {
  direction: rtl;
}

.other-language .spectrum-key {
  direction: rtl;
  align-items: center;
}

.spectrum-key {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: flex-end;
}

.least-span {
  color: #B5B3B7;
}

.result-spectrum-keys-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.result-spectrum {
  /* font-family: 'GothicNewBold'; */
  font-size: 15px;
}

.result-spectrum-buy {
  /* font-family: 'GothicNewBold'; */
  font-size: 18px;
  margin-top: 30px;
  max-width: 400px;
  text-align: center;
}


.circle-result {
  width: 150px;
  height: 150px;
  background-color: #252B68;
  border-radius: 100%;
}

.circle-game-logo {
  width: 90px;
  height: 90px;
  background-color: #252B68;
  border-radius: 100%;
}

.exit-button {
  background-color: #D8D6D6;
  /* font-family: 'GothicNewBold'; */
  text-transform: uppercase;

  width: 170px;
}

.pick-button {
  color: white;
  background-color: #0E2673;
  /* font-family: 'GothicNewBold'; */
  text-transform: uppercase;
  width: 160px;
}

.exit-button:hover,
.pick-button:hover {}



.category-card-content {
  cursor: pointer;

}

.situation-card-image {
  display: inline;
}

.category-card img,
.situation-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
}

.button-results {
  color: white;
  background-color: #BD1923;
  padding: 12px 22px;
  border: none;
  border-radius: 20px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  /* font-family: 'GothicNewBold'; */

}

.button-results a {
  text-decoration: none;
  color: white;
  /* font-family: 'GothicNewBold'; */

}

.buttons {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.pick-text,
.results-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pick-text p {
  color: #CFC6D8;
  font-size: 1.7em;
  font-weight: bold;
}



.category-card p {
  font-size: 16px;
  font-weight: bold;
}

/* Result page*/

.results-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-item {
  display: contents;
  margin-bottom: 1rem;
}

.result-item-category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  /* between items  horizontally */
  margin: -30px;

}

.result-item img {
  width: 300px;
  /* Adjust the width as needed */
  height: auto;
}

.rc-progress-line-path {
  border-radius: 12px;
  border: 2px solid #0E2673;
}


.result-percentage {
  font-size: 26px;
}

.result-item-category p {
  font-weight: bold;

}

.result-item .logo {
  width: 140px;
}

.results-percentage-container {
  margin: 40px;
  display: inline-table;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.highlighted-red-middle {
  box-shadow: 0px 0px 20px rgba(252, 46, 46, 0.46);
  top: 18vh;
  margin-left: 4vw;
}

@media (max-width: 767px) {

  .results-page {
    margin-top: 40px;
  }

  .rounds-score {
    width: 205px !important;
    position: absolute;
    top: 25px;
    right: 105px;
    width: 5em;
    height: 5em;
    background-color: rgba(255, 0, 0, 0);
    color: #b8c2d8;
    transform: scale(0.8);
  }

  .rounds-score p {
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
    line-height: 1.3;
  }

  .logo-container .logo {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 5em;
  }

  .deck {
    display: flex;
    flex-wrap: wrap;
    margin: 130px 20px 20px 10px;
    justify-content: center;
  }

  .situation-card {
    margin-left: -60px;
    border-radius: 5%;
    width: calc(48% - 10px);
    border-radius: 5%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }


  .pick-text p {
    color: #b8c2d8;
    font-size: 1.1em;
    font-weight: bold;
  }

  .category-card p {
    font-size: 16px;
    font-weight: bold;
  }

  .category-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 10px;
  }

  .category-card-container {
    display: inline-block;
    /* Styles for each card item */
    /* Adjust the width, height, margin, and other styles as per your requirements */
    width: calc(30% - 10px);
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 6%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 10px 5px;
    padding: 0px;
    text-align: center;
    cursor: pointer;
    transition: all 0s ease;
    position: initial;

  }

  .category-card.selected {
    display: inline-block;
    transform: scale(0.95);
    top: -10em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
    width: 170px;
    height: 240px;
    z-index: 10;
    border-radius: 6%;
    margin-left: -35px;
  }

  .category-card img,
  .situation-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6%;
  }

  .picked-card {
    /* display: inline-block;
    position: absolute;
    top: 13.2vh;
    right: calc(-63vw + 10px);
    width: calc(100% - 0vw);
    transform: scale(1.1); */
    /* display: inline-block;
    position: absolute;
    top: 10.5%;
    right: calc(-32vw + 10px);
    width: calc(80% - 1vw); */
    display: inline-block;
    position: absolute;
    top: 10.5%;
    right: calc(14vw + 10px);
    width: 110px;

  }


  .picked-card .category-card {
    width: 110px;
  }

  .picked-card .category-card-container {
    width: 100%;
  }

  .situation-card.alone {
    margin-left: 0px;
    border-radius: 5%;
    width: calc(66% - 10px);
    border-radius: 5%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  }

  .countdown-container {
    top: 5%;
    left: 38%;
    position: absolute;
    z-index: 5;
  }

  .countdown-container h3 {
    font-size: 16px;
  }

  .result-item img {
    width: 200px;
    /* Adjust the width as needed */
    height: auto;
  }

  .rc-progress-line-path {
    border-radius: 12px;
    border: 2.5px solid black;
    width: 85vw;
  }


  .result-percentage {
    font-size: 20px;
  }

  .result-item-category p {
    font-weight: bold;

  }

  .result-item .logo {
    width: 120px;
  }

  .result-item p {
    font-size: 25px;
  }

  .result-spectrum-keys-container {
    width: 340px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .result-spectrum-buy {
    max-width: 350px;
  }

  .result-item-category {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    /* between items  horizontally */
    margin: -30px;
  }

  .highlighted-red-middle {
    top: 8.2vh;
    box-shadow: 0px 0px 20px rgba(252, 46, 46, 0.46);
  }

  .progress-bar-container {
    width: max-content;
  }

  .deck.end-game {}
}

.highlighted-red {
  box-shadow: 0px 0px 20px rgba(252, 46, 46, 0.46);
}


.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
  background-color: #fff;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 180px;
}

.start-game-button {
  text-decoration: none;
  margin-top: 30px;
  /* font-family: 'GothicNewBold'; */
}

.btn-primary {
  text-decoration: none;
  background-color: #BD1923;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.description {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.language-btns {
  margin-bottom: 20px;
}


.deck.end-game {
  margin: 23vh 0px 30px 0px;
}

.btn-language {
  border: none;
  background-color: #0E2673;
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 6px 18px;
  cursor: pointer;
}

.small-text {
  font-size: 8px;
  letter-spacing: 0.1px;
}